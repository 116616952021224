import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import analysisImage from '../assets/images/analysis1.jpg';
import maintenanceImage from '../assets/images/maintenance1.jpg';
import supervisionImage from '../assets/images/supervision1.jpg';
import qualityImage from '../assets/images/quality1.jpg';
import efficiencyImage from '../assets/images/efficiency1.jpg';

const news = [
  {
    title: "Reglas del Servicio de Acreditación",
    description: "Descargue la versión 10 de las reglas de acreditación de ONAC.",
    link: "https://onac.org.co/documentos/rac-3-0-01-antes-r-ac-01/",
  },
  {
    title: "Calcomanía de Como Conduzco",
    description: "Resolución 6462 del 28 de junio de 2024",
    link: "https://www.supertransporte.gov.co/documentos/2024/Junio/Comunicaciones_29/Resolucion_6462.pdf",
  },
  
];

const Content = () => {
  return (
    <Container className="page-content">
      <Row>
        {/* Sección principal */}
        <Col md={9} className="mb-4">
          <Row>
            <Col md={6} className="mb-4">
              <Card className="shadow-lg bg-dark text-light h-100">
                <Card.Img variant="top" src={analysisImage} alt="Análisis Metrológico" />
                <Card.Body>
                  <Card.Title>Análisis Metrológico</Card.Title>
                  <Card.Text>
                    Nuestra plataforma no solo analiza, sino que perfecciona tus equipos de medición, asegurando que cumplan con todas las normativas y estándares de calidad, protegiendo la reputación de tu centro.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="shadow-lg bg-dark text-light h-100">
                <Card.Img variant="top" src={maintenanceImage} alt="Registro y Control de Mantenimiento" />
                <Card.Body>
                  <Card.Title>Registro y Control de Mantenimiento</Card.Title>
                  <Card.Text>
                    Programa mantenimientos con precisión, gestiona el historial completo de cada equipo, asegurando su rendimiento óptimo y duradero.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-4">
              <Card className="shadow-lg bg-dark text-light h-100">
                <Card.Img variant="top" src={supervisionImage} alt="Supervisión de Personal" />
                <Card.Body>
                  <Card.Title>Supervisión de Personal</Card.Title>
                  <Card.Text>
                    Nuestra plataforma facilita la supervisión y genera estadísticas que te permiten identificar las áreas de formación necesarias, garantizando un equipo siempre un paso adelante.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="shadow-lg bg-dark text-light h-100">
                <Card.Img variant="top" src={qualityImage} alt="Compromiso con la Calidad" />
                <Card.Body>
                  <Card.Title>Compromiso con la Calidad</Card.Title>
                  <Card.Text>
                    Nos comprometemos a mantener los más altos niveles de excelencia en cada uno de nuestros servicios, porque tu satisfacción y confianza son nuestra máxima prioridad.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-4">
              <Card className="shadow-lg bg-dark text-light h-100">
                <Card.Img variant="top" src={efficiencyImage} alt="Eficiencia y Ahorro de Tiempo" />
                <Card.Body>
                  <Card.Title>Eficiencia y Ahorro de Tiempo</Card.Title>
                  <Card.Text>
                    Automatiza tareas que antes te consumían horas. Nuestra plataforma permite ahorrar tiempo, reducir la carga de trabajo, y maximizar la eficiencia.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* Sección de Noticias */}
        <Col md={3}>
          <Card className="shadow-lg bg-dark text-light">
            <Card.Header as="h5">Noticias Recientes</Card.Header>
            <Card.Body>
              {news.map((item, index) => (
                <div key={index} className="mb-3">
                  <Card.Text>
                    <strong>{item.date}</strong> <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-info">{item.title}</a>
                  </Card.Text>
                  <Card.Text>{item.description}</Card.Text>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
