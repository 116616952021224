import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import '../styles/VerificarCorreo.css';

const VerificarCorreo = () => {
    const navigate = useNavigate(); // Inicializar useNavigate

    useEffect(() => {
        // Desplazarse a la parte superior de la página
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="verificar-correo">
            <h1>¡Registro exitoso!</h1>
            <p>Hemos enviado un enlace de verificación a tu correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para completar el registro.</p>
            <button onClick={() => navigate('/')}>Volver al inicio</button>
        </div>
    );
};

export default VerificarCorreo;
