import React from 'react';

const PoliticaDatosPersonales = () => {
  return (
    <div className="container mt-4">
      <h3>Política de Tratamiento de Datos Personales</h3>
      <p>
        En METROCDA, nos comprometemos a proteger la privacidad de todos los usuarios que interactúan con nuestro sistema. 
        Esta política describe cómo recolectamos, usamos, y protegemos los datos personales que usted proporciona al utilizar 
        nuestros servicios, conforme a la Ley 1581 de 2012 y el Decreto 1377 de 2013 de la República de Colombia, normativas 
        que regulan el tratamiento de datos personales en Colombia.
      </p>

      <h4>Recolección de Información</h4>
      <p>
        Al registrarse en nuestra plataforma, usted de manera voluntaria nos proporciona información personal como su nombre, 
        apellidos, correo electrónico, número de teléfono, y otros datos necesarios para la gestión de los servicios que ofrecemos. 
        Esta información es necesaria para poder prestarle los servicios solicitados, como la validación de su identidad, 
        la realización de registros, el acceso a funciones avanzadas de nuestros sistemas, y para asegurar una comunicación eficaz.
      </p>

      <h4>Uso de la Información</h4>
      <p>
        Al aceptar esta política, usted autoriza a METROCDA a utilizar sus datos personales para los siguientes fines:
      </p>
      <ul>
        <li>Brindar y gestionar los servicios solicitados.</li>
        <li>Enviar información relacionada con nuestros productos, servicios, actualizaciones y eventos.</li>
        <li>Realizar encuestas de satisfacción y estudios de mercado con el fin de mejorar nuestra oferta de servicios.</li>
        <li>Enviar ofertas comerciales y promocionales de nuestros productos o servicios relacionados.</li>
        <li>Comunicarse con usted para ofrecerle productos y servicios que puedan ser de su interés.</li>
      </ul>

      <p>
        METROCDA también podrá utilizar la información recolectada para cumplir con obligaciones legales y regulatorias que 
        apliquen a la empresa, de acuerdo con las normativas colombianas sobre protección de datos personales.
      </p>

      <h4>Transferencia Internacional de Datos</h4>
      <p>
        Si usted se registra o utiliza nuestros servicios desde un país distinto a Colombia, al aceptar esta política, 
        usted reconoce y acepta que sus datos personales serán tratados de acuerdo con las leyes de la República de Colombia. 
        En este sentido, aunque esté ubicado fuera de Colombia, su información estará sujeta a las normativas colombianas sobre 
        protección de datos personales, incluyendo la Ley 1581 de 2012 y el Decreto 1377 de 2013.
      </p>

      <p>
        Asimismo, en caso de que se requiera transferir sus datos personales a terceros ubicados en otros países, 
        METROCDA se compromete a garantizar que dichos terceros cumplan con estándares adecuados de protección de datos 
        de acuerdo con la legislación aplicable, mediante la implementación de las garantías necesarias para la seguridad y 
        confidencialidad de sus datos.
      </p>

      <h4>Derechos del Titular de los Datos</h4>
      <p>
        Como titular de los datos personales, usted tiene los siguientes derechos:
      </p>
      <ul>
        <li>Conocer, actualizar y rectificar sus datos personales en cualquier momento.</li>
        <li>Solicitar la eliminación de sus datos cuando considere que estos ya no son necesarios para los fines establecidos, 
        o cuando se haya agotado la relación contractual.</li>
        <li>Solicitar información sobre el uso que se le ha dado a sus datos personales.</li>
        <li>Revocar la autorización otorgada para el tratamiento de sus datos en cualquier momento, siempre que no lo impida 
        una obligación legal o contractual.</li>
      </ul>

      <p>
        Para ejercer estos derechos, puede contactarnos a través de los canales de atención dispuestos para la gestión de solicitudes 
        relacionadas con el tratamiento de datos personales, o bien a través de nuestro sitio web. La solicitud será atendida 
        conforme a los términos establecidos por la ley colombiana.
      </p>

      <h4>Tratamiento de Datos para Fines Comerciales y Publicitarios</h4>
      <p>
        Al aceptar esta política, usted nos autoriza explícitamente a utilizar sus datos personales para enviarle información 
        comercial, ofertas promocionales, y material publicitario relacionado con nuestros productos y servicios. Esta información 
        será enviada a través de correos electrónicos, llamadas telefónicas, mensajes de texto (SMS) o cualquier otro medio digital.
      </p>

      <p>
        Además, podremos ponernos en contacto con usted para participar en encuestas o estudios de mercado que nos ayuden a mejorar 
        la calidad de nuestros productos y servicios. Estas encuestas podrán ser realizadas de manera directa o a través de 
        proveedores autorizados, siempre garantizando la confidencialidad y seguridad de su información.
      </p>

      <h4>Medidas de Seguridad</h4>
      <p>
        METROCDA implementa medidas de seguridad técnicas, administrativas y físicas para proteger su información personal 
        contra el acceso no autorizado, la pérdida o alteración de los datos. Estas medidas están alineadas con los mejores 
        estándares de la industria y se ajustan a lo establecido por la normativa colombiana.
      </p>

      <p>
        Aunque adoptamos todas las precauciones razonables para proteger su información, es importante señalar que ninguna 
        transmisión de datos a través de internet es completamente segura. Por tanto, METROCDA no puede garantizar la seguridad 
        absoluta de los datos transmitidos electrónicamente y, al utilizar nuestros servicios, usted reconoce y acepta este riesgo.
      </p>

      <h4>Actualización de la Política</h4>
      <p>
        METROCDA se reserva el derecho de modificar esta política en cualquier momento. Cualquier cambio será comunicado 
        oportunamente a través de nuestros canales oficiales, y se entiende que al continuar utilizando nuestros servicios 
        después de la notificación de cambios, usted acepta las modificaciones introducidas.
      </p>

      <p>
        Si tiene alguna duda o inquietud sobre nuestra política de tratamiento de datos personales, no dude en contactarnos 
        a través de nuestro servicio de atención al cliente.
      </p>
    </div>
  );
};

export default PoliticaDatosPersonales;
