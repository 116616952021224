import React from 'react';
import './Loader.css'; // Asegúrate de crear este archivo CSS

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-content">
      
        <img src={`${process.env.PUBLIC_URL}/gif/procesando.gif`} alt="Cargando..." />

        <p>Cargando...</p>
      </div>
    </div>
  );
};

export default Loader;
