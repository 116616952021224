import React from 'react';
import '../styles/Supervision.css';

const Supervision = () => {
  return (
    <div className="supervision-page">
      <h1>La Importancia de la Supervisión del Personal en los CDA</h1>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/supervision-importance.jpg`} alt="Importancia de la Supervisión" />
        </div>
        <div className="text-container">
          <h2>1. Garantía de Calidad y Consistencia</h2>
          <p>
            La supervisión del personal en los Centros de Diagnóstico Automotor (CDA) es esencial para asegurar que los técnicos y operativos mantengan un alto nivel de calidad y consistencia en sus servicios. La supervisión ayuda a garantizar que se sigan los procedimientos estandarizados, evitando errores que puedan afectar la precisión del diagnóstico y la satisfacción del cliente.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container right">
          <img src={`${process.env.PUBLIC_URL}/images/quality-control.jpg`} alt="Control de Calidad" />
        </div>
        <div className="text-container">
          <h2>2. Cumplimiento de Normativas y Estándares</h2>
          <p>
            La supervisión asegura que los técnicos cumplan con las normativas y estándares requeridos. Esto incluye la adherencia a los procedimientos de diagnóstico, el uso correcto del equipo y la implementación de prácticas de seguridad. Un supervisor bien informado puede detectar y corregir desviaciones antes de que se conviertan en problemas mayores.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/safety-practices.jpg`} alt="Prácticas de Seguridad" />
        </div>
        <div className="text-container">
          <h2>3. Seguridad y Prevención de Riesgos</h2>
          <p>
            La seguridad es una prioridad en cualquier entorno de trabajo, y la supervisión juega un papel crucial en la identificación y mitigación de riesgos. Los supervisores deben garantizar que se sigan las prácticas de seguridad, lo que protege a los empleados y reduce la probabilidad de accidentes en el lugar de trabajo.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container right">
          <img src={`${process.env.PUBLIC_URL}/images/staff-development.jpg`} alt="Desarrollo del Personal" />
        </div>
        <div className="text-container">
          <h2>4. Desarrollo Profesional y Motivación</h2>
          <p>
            Una supervisión efectiva contribuye al desarrollo profesional y la motivación del personal. Proporcionar retroalimentación constructiva y oportunidades de formación ayuda a los empleados a mejorar sus habilidades y a mantenerse motivados, lo que repercute positivamente en el desempeño general del CDA.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/continuous-improvement.jpg`} alt="Mejora Continua" />
        </div>
        <div className="text-container">
          <h2>5. Mejora Continua y Eficiencia Operativa</h2>
          <p>
            La supervisión también fomenta la mejora continua y la eficiencia operativa. Al analizar el desempeño del personal y los procesos, los supervisores pueden identificar áreas de mejora y optimizar los procedimientos. Esto asegura que el CDA se mantenga competitivo y eficiente en un entorno en constante cambio.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Supervision;
