import React from 'react';
import '../styles/Mantenimiento.css'; // Asegúrate de crear este archivo CSS

const Mantenimiento = () => {
  return (
    <div className="maintenance-page">
      <h1>La Importancia del Mantenimiento en los Equipos de un CDA</h1>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/maintenance-introduction.jpg`} alt="Introducción al mantenimiento" />
        </div>
        <div className="text-container">
          <h2>1. ¿Qué es el Mantenimiento?</h2>
          <p>
            El mantenimiento en un Centro de Diagnóstico Automotor (CDA) implica una serie de actividades destinadas a asegurar que los equipos operen de manera óptima. Esto incluye la reparación, ajuste y verificación regular de los equipos para prevenir fallos y garantizar la precisión de las pruebas realizadas. El mantenimiento adecuado es esencial para la eficiencia y la seguridad en el diagnóstico de vehículos.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container right">
          <img src={`${process.env.PUBLIC_URL}/images/maintenance-types.jpg`} alt="Tipos de mantenimiento" />
        </div>
        <div className="text-container">
          <h2>2. Tipos de Mantenimiento</h2>
          <p>
            Los principales tipos de mantenimiento incluyen el mantenimiento preventivo, correctivo, predictivo y proactivo. El mantenimiento preventivo se realiza de manera programada para evitar fallos, mientras que el correctivo se lleva a cabo cuando los equipos presentan fallos inesperados. El mantenimiento predictivo utiliza tecnologías avanzadas para anticipar problemas, y el proactivo se enfoca en la mejora continua y la eliminación de causas raíz de problemas.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/maintenance-importance.jpg`} alt="Importancia del mantenimiento" />
        </div>
        <div className="text-container">
          <h2>3. Importancia del Mantenimiento</h2>
          <p>
            Un mantenimiento adecuado asegura la precisión de los equipos, mejora la seguridad del personal y de los vehículos, y optimiza la eficiencia operativa del CDA. Además, ayuda a cumplir con las normativas vigentes, reducir costos operativos y mejorar la confiabilidad y la reputación del CDA.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container right">
          <img src={`${process.env.PUBLIC_URL}/images/maintenance-strategies.jpg`} alt="Estrategias de mantenimiento" />
        </div>
        <div className="text-container">
          <h2>4. Estrategias de Mantenimiento</h2>
          <p>
            Implementar una combinación de mantenimiento preventivo, predictivo y proactivo puede ayudar a maximizar la vida útil de los equipos y a minimizar los tiempos de inactividad. Las estrategias efectivas de mantenimiento también incluyen la capacitación del personal y la adopción de tecnologías avanzadas para la monitorización de condiciones.
          </p>
        </div>
      </section>

      <section className="section">
        <div className="image-container left">
          <img src={`${process.env.PUBLIC_URL}/images/maintenance-benefits.jpg`} alt="Beneficios del mantenimiento" />
        </div>
        <div className="text-container">
          <h2>5. Beneficios del Mantenimiento</h2>
          <p>
            Los beneficios del mantenimiento incluyen la reducción de fallos imprevistos, la mejora en la calidad del servicio, el aumento de la eficiencia operativa y el cumplimiento con normativas y estándares. Un programa de mantenimiento sólido contribuye a la sostenibilidad y éxito a largo plazo del CDA.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Mantenimiento;
