import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/headers/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Marquee from './components/Marquee';
import VerificarRegistro from './components/VerificarRegistro';
import BuscadorMunicipios from './components/BuscadorMunicipios';
import Inicio from './pages/Inicio';
import Calibrar from './pages/Calibrar';
import Mantenimiento from './pages/Mantenimiento';
import Supervision from './pages/Supervision';
import RegistroInicial from './pages/RegistroInicial';
import VerificarCorreo from './pages/VerificarCorreo';
import ValidadoOk from './pages/ValidadoOk';
import ValidadoOkOlvido from './pages/ValidadoOkOlvido';
import PoliticaDatosPersonales from './pages/PoliticaDatosPersonales';
import Error404 from './pages/Error404';
import Login from './components/Login';
import OlvidoContrasena from './components/OlvidoContrasena';
import ReestablecerContrasena from './components/ReestablecerContrasena';
import api from './auth.js';

import './styles/App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return sessionStorage.getItem('SESSIONID') !== null;
  });

  useEffect(() => {
    document.documentElement.lang = 'es';

    const checkSession = () => {
      const sessionStatus = sessionStorage.getItem('SESSIONID') !== null;
      setIsLoggedIn(sessionStatus);
    };

    checkSession();
    const interval = setInterval(checkSession, 600000); // Comprobar cada 10 minutos

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleActivity = async () => {
      if (isLoggedIn) {
        try {
          const response = await api.post('/renew-session');
          if (response.data.token) {
            sessionStorage.setItem('SESSIONID', response.data.token);
          }
        } catch (error) {
          console.error('Error al renovar la sesión:', error);
        }
      }
    };

    const activityEvents = ['mousemove', 'keydown', 'scroll'];
    activityEvents.forEach(event => window.addEventListener(event, handleActivity));

    return () => {
      activityEvents.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, [isLoggedIn]);

  const handleLogin = (token) => {
    setIsLoggedIn(true);
    sessionStorage.setItem('SESSIONID', token);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem('SESSIONID');
  };

  const shouldShowMarquee = () => !isLoggedIn;

  return (
    <>
      <Header onLogout={handleLogout} />
      <Menu />
      {shouldShowMarquee() && <Marquee />}
      <main className="container mt-4" style={{ paddingTop: '150px', paddingBottom: '150px' }}>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/registroinicial" element={<RegistroInicial />} />
          <Route path="/calibrar" element={<Calibrar />} />
          <Route path="/mantenimiento" element={<Mantenimiento />} />
          <Route path="/supervision" element={<Supervision />} />
          <Route path="/buscar" element={<BuscadorMunicipios />} />
          <Route path="/verificar-correo" element={<VerificarCorreo />} />
          <Route path="/validado-Ok" element={<ValidadoOk />} />
          <Route path="/validado-Ok-Restaura" element={<ValidadoOkOlvido />} />
          <Route path="/verificar-registro/:token" element={<VerificarRegistro />} />
          <Route path="/politica-datos-personales" element={<PoliticaDatosPersonales />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/olvido-contrasena" element={<OlvidoContrasena />} />
          <Route path="/restablecer-contrasena/:token" element={<ReestablecerContrasena />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
