import React from 'react';
import '../styles/Calibra.css';

const Calibra = () => {
  return (
    <div className="calibration-page">
      <h1>La Importancia de la Calibración</h1>

      <section className="section left">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/images/calibration-process.jpg`} alt="Proceso de calibración" />
        </div>
        <div className="text-container">
          <h2>1. ¿Qué es la Calibración?</h2>
          <p>
            La calibración es el proceso mediante el cual se ajustan los instrumentos de medición para garantizar que sus lecturas sean precisas y confiables. Este proceso implica comparar las mediciones de un dispositivo con un estándar conocido y realizar los ajustes necesarios para corregir cualquier desviación.
          </p>
        </div>
      </section>

      <section className="section right">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/images/precision-tools.jpg`} alt="Instrumentos de precisión" />
        </div>
        <div className="text-container">
          <h2>2. Importancia de la Calibración</h2>
          <p>
            La calibración es esencial para garantizar la calidad de los productos, la seguridad en los procesos y el cumplimiento de las normas. Los instrumentos de medición precisos son fundamentales en sectores como la manufactura, la salud, la energía y la investigación científica.
          </p>
        </div>
      </section>

      <section className="section left">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/images/calibration-benefits.jpg`} alt="Beneficios de la calibración" />
        </div>
        <div className="text-container">
          <h2>3. Beneficios de la Calibración</h2>
          <p>
            Entre los principales beneficios de la calibración se encuentran la reducción de errores, la mejora de la calidad del producto, el aumento de la eficiencia operativa, y el cumplimiento con normativas y estándares. Además, una calibración adecuada puede prevenir accidentes y garantizar la seguridad de los trabajadores.
          </p>
        </div>
      </section>

      <section className="section right">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/images/verifica.jpg`} alt="Calibraciones intermedias" />
        </div>
        <div className="text-container">
          <h2>4. Calibraciones Intermedias</h2>
          <p>
            Las calibraciones intermedias son cruciales para mantener la precisión de los instrumentos en situaciones donde las condiciones pueden variar, como cambios de temperatura o humedad. Estas calibraciones ayudan a evitar desviaciones significativas en las mediciones, asegurando así la consistencia en los resultados.
          </p>
        </div>
      </section>

      <section className="section left">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/images/calibration-conclusion.jpg`} alt="Conclusión sobre la calibración" />
        </div>
        <div className="text-container">
          <h2>5. Conclusión</h2>
          <p>
            La calibración no es solo una necesidad técnica, sino también una responsabilidad ética y profesional. Garantizar la exactitud de las mediciones es crucial para la calidad, la seguridad y la confianza en los productos y servicios ofrecidos. Adoptar una estrategia de calibración sólida es fundamental para cualquier industria que dependa de la precisión.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Calibra;
