import React from 'react';

const Error404 = () => {
    return (
        <div>
            <h1>Página no encontrada</h1>
            <p>Lo sentimos, la página que estás buscando no existe.</p>
        </div>
    );
};

export default Error404;
