import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logometro from '../../assets/images/METROCDA1.png';
import '../headers/Header.css';

const Header = () => {
  const token = sessionStorage.getItem('SESSIONID'); // Leer el token desde sessionStorage
  let usuarioNombre = '';
  let usuarioRol = '';

  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      usuarioNombre = payload.nombre; // Ajusta el nombre del campo según el contenido de tu JWT
      usuarioRol = payload.nrol; // Ajusta el nombre del campo según el contenido de tu JWT
    } catch (error) {
      console.error('Error al decodificar el token:', error);
    }
  }

  const handleLogout = () => {
    sessionStorage.removeItem('SESSIONID'); // Eliminar el token de sesión al cerrar sesión
    window.location.href = '/login'; // Redirigir al usuario a la página de inicio de sesión
  };

  return (
    <header className="header">
      <Navbar className="bg-gradient w-100" expand="lg">
        <Container className="d-flex justify-content-between align-items-center">
          <Navbar.Brand href="#home" className="d-flex align-items-center">
            <img
              src={logometro}
              width="350"
              height="auto"
              className="d-inline-block align-top"
              alt="logometro"
            />
            <span className="d-none d-lg-inline ms-2">Asegurando las mediciones de los CDA's</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center">
              {token ? (
                <div className="text-end">
                  <p className="mb-0">Bienvenid@, {usuarioNombre}</p>
                  <small className="text-muted">{usuarioRol}</small>
                  <br />
                  <Link to="#" className="btn btn-danger btn-sm" onClick={handleLogout}>Cerrar Sesión</Link>
                </div>
              ) : (
                <>
                  <Link to="/login" className="btn btn-primary btn-sm me-2">Iniciar Sesión</Link>
                  <Link to="/registroinicial" className="btn btn-secondary btn-sm">Registrarte</Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
