import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="menu">
      {isMobile ? (
        <>
          <button className="menu-toggle" onClick={toggleMenu}>
            ☰
          </button>
          {isMenuOpen && (
            <Nav className="menu-items bg-dark py-2 flex-column">
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={toggleMenu} className="text-light">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/calibrar" onClick={toggleMenu} className="text-light">Calibrar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/mantenimiento" onClick={toggleMenu} className="text-light">Mantenimiento</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/supervision" onClick={toggleMenu} className="text-light">Supervisión</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/contacto" onClick={toggleMenu} className="text-light">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
          )}
        </>
      ) : (
        <Nav className="menu-items bg-dark py-2 flex-row justify-content-center">
          <Nav.Item>
            <Nav.Link as={Link} to="/" className="text-light">Inicio</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/calibrar" className="text-light">Calibrar</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/mantenimiento" className="text-light">Mantenimiento</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/supervision" className="text-light">Supervisión</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/contacto" className="text-light">Contacto</Nav.Link>
          </Nav.Item>
        </Nav>
      )}
    </nav>
  );
};

export default Menu;
