import React, { useState } from 'react';
import axios from 'axios';

function BuscadorMunicipios() {
    const [nombre, setNombre] = useState('');
    const [resultados, setResultados] = useState([]);

    const buscarMunicipios = async () => {
        try {
            const response = await axios.get('http://localhost:8080/api/v1/municipios/buscar', {
                params: { nombre },
                headers: { 'api-key': 'hkj4KJ8hsdf9jskj3KJ8sd9Ks2JkBsz3kKsdjhs9J=' }
            });
            setResultados(response.data);
        } catch (error) {
            console.error('Error buscando municipios:', error);
        }
    };

    return (
        <div className="container">
            <h1>Buscar Municipios</h1>
            <input
                type="text"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                placeholder="Nombre del municipio"
                className="form-control"
            />
            <button onClick={buscarMunicipios} className="btn btn-primary mt-2">Buscar</button>
            <ul className="list-group mt-3">
                {resultados.map(municipio => (
                    <li key={municipio.codigo} className="list-group-item">
                        {municipio.nombre} - {municipio.departamento.nombre}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BuscadorMunicipios;
