import axios from 'axios';

// Crea una instancia de Axios con la URL base de la API
const api = axios.create({
  baseURL: 'https://metrocda.co/api/v1',
});

// Agrega un interceptor de solicitud para incluir la API Key o el token JWT según el endpoint
api.interceptors.request.use(
  (config) => {
    console.log('Enviando solicitud a:', config.url); // Log de la URL
    console.log('Config de solicitud:', config); // Log de la configuración de la solicitud
    
    // API Key para todos los endpoints
    const apiKey = 'hkj4KJ8hsdf9jskj3KJ8sd9Ks2JkBsz3kKsdjhs9J'; // Clave API
    if (apiKey) {
      config.headers['API-KEY'] = apiKey;
    }

    // Token JWT para otros endpoints (si es necesario, descomentar y ajustar)
    // const token = 'your-jwt-token'; // Token JWT
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    return config;
  },
  (error) => {
    console.error('Error en la solicitud:', error); // Log de error
    return Promise.reject(error);
  }
);

// Agrega un interceptor de respuesta para manejar respuestas de error
api.interceptors.response.use(
  (response) => {
    console.log('Respuesta recibida:', response); // Log de la respuesta
    return response;
  },
  (error) => {
    console.error('Error en la respuesta:', error); // Log de error
    return Promise.reject(error);
  }
);

export default api;


