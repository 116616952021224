import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Importa useNavigate
import api from '../auth.js'; // Importa la instancia de Axios

const CrearContrasena = ({ token, correo, setShowForm }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userIP, setUserIP] = useState('');
  const navigate = useNavigate(); // Inicializa useNavigate

  // Expresión regular para la validación de la contraseña
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  useEffect(() => {
    // Obtener la IP del usuario al cargar el componente
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error al obtener la IP:', error);
      }
    };

    fetchIP();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // Verificar si las contraseñas coinciden
    if (password !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }

    // Verificar si la contraseña cumple con los requisitos
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        'La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un carácter especial.'
      );
      return;
    }

    // Si todo está bien, hacemos la solicitud al backend para crear la contraseña
    const apiUrl = '/usuarios/establecer-contrasena';
    const payload = {
      correo: correo,  // Aquí se añade el correo al payload
      contrasena: password,
      ipCambio: userIP, // Enviar la IP del usuario
      metodoCambio: "Nueva"
    };

    // Mostrar la ruta y el JSON en la consola
    //console.log("Ruta de la API:", apiUrl);
    //console.log("JSON enviado:", JSON.stringify(payload));

    try {
      const response = await api.post(apiUrl, payload);

      // Mostrar el mensaje del backend
      if (response.data) {
        if (response.data.codigo === 1) {
          // Redirigir a /validado-ok si la contraseña se ha creado correctamente
          navigate('/validado-ok');
        } else {
          setErrorMessage(response.data.mensaje);
        }
      } else {
        setErrorMessage('Error al crear la contraseña. Por favor, intenta de nuevo.');
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al intentar crear la contraseña.');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: '20px' }}>
      <div className="col-md-4">
        <h2 className="text-center">Crea tu Contraseña</h2>
  
        {/* Mostrar mensajes de éxito o error */}
        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
  
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Nueva Contraseña:</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Ingresa tu nueva contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
  
          <div className="form-group mt-3">
            <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              placeholder="Confirma tu nueva contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
  
          <button type="submit" className="btn btn-primary mt-4 w-100">
            Crear Contraseña
          </button>
        </form>
      </div>
    </div>
  );
};

const VerificarRegistro = () => {
  const { token } = useParams(); // Recibimos el token desde la URL
  const [responseMessage, setResponseMessage] = useState(''); // Estado para guardar el mensaje de respuesta
  const [codigo, setCodigo] = useState(null); // Estado para guardar el código de respuesta
  const [showForm, setShowForm] = useState(false); // Estado para controlar la visibilidad del formulario
  const [userEmail, setUserEmail] = useState(''); // Estado para almacenar el correo electrónico del usuario
  const navigate = useNavigate(); // Inicializamos useNavigate

  useEffect(() => {
    const verificarUsuario = async () => {
      const url = `/usuarios/verificar?token=${token}`; // Usamos la URL relativa

      try {
        // Hacer una llamada GET al endpoint /verificar con el token
        const response = await api.get(url); // Usar la instancia de Axios

        // Comprobar si la verificación fue exitosa y guardar el mensaje y código de la respuesta
        setCodigo(response.data.codigo); // Guardar el código
        setResponseMessage(response.data.mensaje); // Guardar el mensaje de respuesta

        // Mostrar el formulario solo si el código es 1
        if (response.data.codigo === 1) {
          setShowForm(true); // Mostrar el formulario
          setUserEmail(response.data.email); // Guardar el correo electrónico del usuario desde la respuesta
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        setResponseMessage("Ocurrió un error al intentar verificar el token.");
        setCodigo(0); // Token inválido
      }
    };

    verificarUsuario(); // Llamar a la función cuando el componente se monte
  }, [token]);

  const solicitarNuevoToken = async () => {
    const url = `/usuarios/solicitar-nuevo-token?token=${token}`;

    try {
      // Enviamos el token en el cuerpo de la solicitud
      const response = await api.post(url);
      
      if (response.data.codigo === 1) {
        setTimeout(() => {
          navigate('/verificar-correo');
        }, 10);
      } else {
        setResponseMessage(response.data.mensaje);
      }

      // Mostrar el mensaje de respuesta del backend
    } catch (error) {
      if (error.response) {
        setResponseMessage(`Error ${error.response.status}: ${error.response.data.mensaje || 'Error en la solicitud'}`);
      } else if (error.request) {
        setResponseMessage('No se recibió respuesta del servidor. Inténtalo más tarde.');
      } else {
        setResponseMessage(`Error: ${error.message}`);
      }
      
      console.error('Detalles del error:', error);
    }
  };

  return (
    <div className="container mt-4">
      {/* Mostrar el mensaje de respuesta */}
      {responseMessage && <div className="alert alert-info mt-3">{responseMessage}</div>}

      {/* Dependiendo del código de respuesta, mostramos diferentes componentes */}
      {codigo === 1 && showForm ? (
        <CrearContrasena token={token} correo={userEmail} setShowForm={setShowForm} /> // Pasar el correo al componente CrearContrasena
      ) : codigo === 2 ? (
        <div className="alert alert-warning mt-3">El token ha expirado. Por favor, solicita uno nuevo.</div>
      ) : codigo === 3 ? (
        <div className="alert alert-danger mt-3">
          El token es inválido o ha expirado. <Link to="#" onClick={solicitarNuevoToken}>Solicitar nuevo token</Link>
        </div>
      ) : codigo === 0 ? (
        <div className="alert alert-danger mt-3">Token inválido. Verificación fallida.</div>
      ) : null}
    </div>
  );
};

export default VerificarRegistro;
