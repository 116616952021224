import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/RegistroInicial.css';
import api from '../auth.js';
import Loader from '../components/Loader';

const RegistroInicial = () => {
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [selectedCiudad, setSelectedCiudad] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    correo: '',
    nombre: '',
    apellidos: '',
    telefono: '',
    departamento: '',
    ciudadUsuario: '',
    autorizacion: false,
  });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const rLog = process.env.REACT_APP_LOG_API_CALLSL1;
  if (rLog  === 'true') {
    console.log(`GET  Ruta de la api  ${api.defaults.baseURL}/departamentos`);
  } 

  console.log(`GET ${api.defaults.baseURL}/departamentos`);
  const fetchDepartamentos = async () => {
    try {
      const response = await api.get('/departamentos');
      setDepartamentos(response.data);
    } catch (error) {
      console.error('Error al obtener departamentos:', error.response?.data || error.message);
    }
  };

  const fetchCiudades = async () => {
    if (selectedDepartamento) {
      try {
        const response = await api.get(`/municipios/departamento/${selectedDepartamento}`);
        setCiudades(response.data);
        if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
          console.log(`GET ${api.defaults.baseURL}/municipios/departamento/${selectedDepartamento}`, response.data);
        }
      } catch (error) {
        console.error('Error al obtener ciudades:', error.response?.data || error.message);
      }
    } else {
      setCiudades([]);
    }
  };

  useEffect(() => {
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    fetchCiudades();
  }, [selectedDepartamento]);

  const handleDepartamentoChange = (e) => {
    setSelectedDepartamento(e.target.value);
    setSelectedCiudad('');
    setFormData({ ...formData, departamento: e.target.value });
  };

  const handleCiudadChange = (e) => {
    setSelectedCiudad(e.target.value);
    setFormData({ ...formData, ciudadUsuario: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCiudades = ciudades.filter(ciudad =>
    ciudad.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!emailPattern.test(formData.correo)) {
      errors.correo = 'El correo electrónico no es válido.';
    }
    if (formData.telefono && !phonePattern.test(formData.telefono)) {
      errors.telefono = 'El número de teléfono debe tener 10 dígitos.';
    }
    if (!selectedDepartamento) {
      errors.departamento = 'Debes seleccionar un departamento.';
    }
    if (!selectedCiudad) {
      errors.ciudadUsuario = 'Debes seleccionar una ciudad.';
    }
    if (!formData.autorizacion) {
      errors.autorizacion = 'Debes aceptar las políticas de tratamiento de datos personales.';
    }
    if (!captchaVerified) {
      errors.captcha = 'Debes verificar que no eres un robot.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setMessage('');
      setIsSuccessful(false);

      try {
        const response = await api.post('/usuarios/registro', formData);
        const { codigo, mensaje } = response.data;

        if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
          console.log(`POST ${api.defaults.baseURL}/usuarios/registro`, formData);
          console.log(`Response:`, response.data);
        }

        switch (codigo) {
          case 0:
            setAlertType('error');
            break;
          case 1:
            setAlertType('success');
            break;
          case 2:
            setAlertType('warning');
            break;
          case 3:
            setAlertType('link-warning');
            break;
          default:
            setAlertType('info');
            break;
        }

        setMessage(mensaje);
        scrollToTop();

        if (codigo === 1) {
          setIsSuccessful(true);
          setTimeout(() => {
            navigate('/verificar-correo');
          }, 10);
        }
      } catch (error) {
        setMessage('Error al registrar el usuario: ' + (error.response?.data || error.message));
        setAlertType('error');
        scrollToTop();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleReset = () => {
    setFormData({
      correo: '',
      nombre: '',
      apellidos: '',
      telefono: '',
      departamento: '',
      ciudadUsuario: '',
      autorizacion: false,
    });
    setSelectedDepartamento('');
    setSelectedCiudad('');
    setSearchTerm('');
    setErrors({});
    setCaptchaVerified(false);
    setMessage('');
    setAlertType('');
  };

  return (
    <div className="registro-inicial">
      {loading && <Loader message="Registrando, por favor espera..." />}

      {!loading && (
        <form className="formulario" onSubmit={handleSubmit}>
          <h1>Registro Inicial</h1>

          {message && (
            <div className={`alert ${alertType} alert-top`}>
              {alertType === 'link-warning' ? (
                <>
                  {message} <a href="/politica-datos-personales">Ver acuerdo de datos</a>
                </>
              ) : (
                message
              )}
            </div>
          )}

          <div className="form-group">
            <label htmlFor="correo">Correo</label>
            <input
              type="email"
              id="correo"
              name="correo"
              value={formData.correo}
              onChange={handleInputChange}
              required
            />
            {errors.correo && <div className="error">{errors.correo}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="apellidos">Apellidos</label>
            <input
              type="text"
              id="apellidos"
              name="apellidos"
              value={formData.apellidos}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="tel"
              id="telefono"
              name="telefono"
              value={formData.telefono}
              onChange={handleInputChange}
            />
            {errors.telefono && <div className="error">{errors.telefono}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="departamento">Departamento</label>
            <select
              id="departamento"
              value={selectedDepartamento}
              onChange={handleDepartamentoChange}
            >
              <option value="">Selecciona un departamento</option>
              {departamentos.map(dpto => (
                <option key={dpto.codigo} value={dpto.codigo}>{dpto.nombre}</option>
              ))}
            </select>
            {errors.departamento && <div className="error">{errors.departamento}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="ciudadUsuario">Ciudad</label>
            <select
              id="ciudadUsuario"
              value={selectedCiudad}
              onChange={handleCiudadChange}
            >
              <option value="">Selecciona una ciudad</option>
              {filteredCiudades.map(ciudad => (
                <option key={ciudad.codigo} value={ciudad.codigo}>{ciudad.nombre}</option>
              ))}
            </select>
            {errors.ciudadUsuario && <div className="error">{errors.ciudadUsuario}</div>}
          </div>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange}
            />
            {errors.captcha && <div className="error">{errors.captcha}</div>}
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="autorizacion"
                checked={formData.autorizacion}
                onChange={handleInputChange}
              />
              Acepto las políticas de tratamiento de datos personales <a href="/politica-datos-personales" target="_blank" rel="noopener noreferrer">Ver Aquí</a>
            </label>
            {errors.autorizacion && <div className="error">{errors.autorizacion}</div>}
          </div>

          <button type="submit" className="btn btn-primary">Registrarse</button>
          <button type="button" className="btn btn-secondary" onClick={handleReset}>Reiniciar</button>
        </form>
      )}
    </div>
  );
};

export default RegistroInicial;
