import React, { useState } from 'react';
import api from '../auth.js';  
import 'bootstrap/dist/css/bootstrap.min.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
    const [correo, setCorreo] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMensaje('');

        if (!captchaVerified) {
            setMensaje('Por favor verifica que no eres un robot.');
            return;
        }

        const payload = { correo, contrasena };
        console.log('Datos enviados a la API:', payload);
        
        const apiUrl = `${api.defaults.baseURL}/login`;
        console.log('Ruta completa de la API:', apiUrl);

        try {
            const response = await api.post(apiUrl, payload);
            console.log('Respuesta de la API:', response.data);

            const token = response.data.token; // Asegúrate de que el token esté en response.data.token

            if (token) {
                sessionStorage.setItem('SESSIONID', token); // Almacena el token en sessionStorage

                setMensaje('Inicio de sesión exitoso');
                
                // Limpiar los campos después del inicio de sesión exitoso
                setCorreo('');
                setContrasena('');
                setCaptchaVerified(false);
                
               window.location.href = '/'; // Redirige a la página principal
            } else {
                setMensaje('No se pudo iniciar sesión.');
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            if (error.response) {
                setMensaje('Error al iniciar sesión: ' + (error.response.data.mensaje || 'Usuario o contraseña no válidos.'));
            } else if (error.request) {
                setMensaje('Error al iniciar sesión, no se recibió respuesta del servidor.');
            } else {
                setMensaje('Error al iniciar sesión: ' + error.message);
            }
        }
    };

    const onCaptchaChange = (value) => {
        setCaptchaVerified(!!value);
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', padding: '0' }}>
            <div className="card" style={{ width: '400px', borderRadius: '10px', padding: '20px' }}>
                <h2 className="text-center">Iniciar Sesión</h2>
                {mensaje && <div className={`alert ${mensaje.includes('exitoso') ? 'alert-success' : 'alert-danger'}`}>{mensaje}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="correo" className="form-label">Correo Electrónico</label>
                        <input
                            type="email"
                            id="correo"
                            className="form-control"
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="contrasena" className="form-label">Contraseña</label>
                        <input
                            type="password"
                            id="contrasena"
                            className="form-control"
                            value={contrasena}
                            onChange={(e) => setContrasena(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary w-100">Iniciar Sesión</button>
                </form>
                <div className="text-center mt-3">
                    <a href="/olvido-contrasena">¿Olvidaste tu contraseña?</a>
                </div>
            </div>
        </div>
    );
};

export default Login;
