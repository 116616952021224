import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ValidadoOk.css'; // Asegúrate de tener el archivo CSS

const ValidadoOkOlvido = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Desplazarse a la parte superior de la página al cargar
    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center vh-100"> {/* Centramos vertical y horizontalmente */}
            <div className="verificacion-exitosa text-center p-5">
                <h1 className="titulo-exitoso mb-4">¡Contraseña cambiada con éxito!</h1>
                <p className="mensaje-exitoso lead">
                    Tu contraseña ha sido actualizada exitosamente. 
                    Ahora puedes ingresar a nuestra plataforma y aprovechar todas las ventajas que ofrecemos.
                </p>
                <p className="mensaje-instrucciones mt-4">
                    Si tienes algún problema, no dudes en contactarnos a través del soporte.
                </p>

                <button 
                    className="btn btn-primary mt-5 px-4 py-2" 
                    onClick={() => navigate('/login')}
                >
                    Iniciar sesión
                </button>
            </div>
        </div>
    );
};

export default ValidadoOkOlvido;
